@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* General styles */
body, html {
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(135deg, #0D3B66, #1E5A99);
  background-size: cover;
}

/* Container styles */
.transfer-form, .details-form {
  width: 100%;
  max-width: 660px; /* Increased to match the width in the image */
  height: auto;
  padding: 30px;
  flex-shrink: 0;
  border-radius: 37px;
  border: 2px solid #ffffff6f;
  background: linear-gradient(181deg, rgba(255, 255, 255, 0.25) 1.15%, rgba(255, 255, 255, 0.25) 98.91%);
  backdrop-filter: blur(34.93928909301758px);
  margin: 50px auto;
  color: white;
}

.file-upload-container {
  display: flex;
  width: calc(100% - 26px); /* Reduced width by 26px */
  height: 295px; /* Reduced from 321px to 295px */
  margin-bottom: 20px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px dashed #CDCDCD;
  background: #EAEAEA;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow-y: auto; /* Add vertical scroll */
}

.file-upload-container:hover {
  background-color: #F5F5F5;
}

.file-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cloud-upload-icon {
  width: 45px;
  height: 31px;
  margin-bottom: 16px;
}

.file-upload-text {
  width: 165px;
  height: 31.238px;
  flex-shrink: 0;
  color: rgba(0, 55, 255, 0.5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 11.214px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.file-upload-container.dragging .file-upload-text {
  color: rgba(0, 112, 240, 0.70);  /* Slightly darker blue when dragging */
}

.file-upload-input {
  display: none;
}

.upload-animation {
  width: 100%;
  height: 50px; /* Adjust as needed */
  margin-top: 10px;
}

/* Form input styles */
input[type="text"],
input[type="email"],
textarea {
  width: 95%;
  padding: 15px;
  margin-bottom: 18px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
}

/* Update the form inputs wrapper */
.form-inputs-wrapper {
  display: flex;
  gap: 18px;
  margin-bottom: 0px;
}

.form-inputs-wrapper input,
.form-inputs-wrapper textarea {
    width: 100%;
}

.left-inputs,
.right-input {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 30px;
}

.right-input textarea {
  height: 100%; /* Make the textarea fill the entire height */
  resize: none;
}

/* Add styles for input placeholders */
input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Ensure proper spacing and alignment */
.details-form {
  display: flex;
  flex-direction: column;
}

.details-form h2 {
  display: flex;
  width: 208px;
  max-height: 40px;
  flex-direction: column;
  justify-content: flex-start; /* Changed from center to flex-start */
  flex-shrink: 0;
  
  color: #EAEAEA;
  text-align: left; /* Changed from center to left */
  font-family: 'Inter', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 86.957% */
  
  /* Additional styles to ensure proper alignment */
  margin: 0 0 20px 0; /* Changed from 0 auto 20px auto to 0 0 20px 0 */
  padding: 0;
}

/* Adjust button styling if needed */
.submit-button {
  margin-top: 0px;
  border-radius: 12px;
}

/* Override for file description inputs */
.file-description-input {
  width: 95%;
  padding: 1px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0) !important; /* Use !important to override */
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 3px;
  color: #fff;
  margin-bottom: 0; /* Remove bottom margin if needed */
}

.file-description-input::placeholder {
  color: rgba(236, 237, 238, 0.30);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.file-description-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0) !important; /* Ensure transparency on focus */
}

textarea {
  min-height: 80px;
  resize: none;
}

/* Button styles */
.submit-button {
  display: block; /* Block display to take full width */
  width: 100%; /* Full width of the container */
  height: 54px;
  padding: 10px 0;
  border-radius: 12px;
  background: #0070F0;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  text-align: center;
  box-shadow: 0 2px 6px 0 #00000040; /* Add box shadow */
}

.submit-button:hover {
  background-color: #005bb5;
  border-radius: 12px;
}

.submit-button:disabled {
  background-color: #6b7280;
  cursor: not-allowed;
}

/* Step 2: File details form */
.file-detail-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.file-video {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.file-title-input,
.file-description-input,
.object-count-select {
  margin-bottom: 10px;
}

.object-count-select {
  background-color: rgba(255, 255, 255, 0.2);
}

h2 {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 0px;
  text-align: left;
}

.extra-info {
  background-color: rgba(255, 255, 255, 0.2);
  resize: none;
}

/* Job Type Button styles */
.job-type-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.job-type-button {
  position: relative;
  border: none; /* Ensure no border on the button */
  width: 285px;  /* Reduced from 325px */
  height: 191px; /* Reduced from 231px */
  flex-shrink: 0;
  border-radius: 20px;
  background: #000;
  color: white;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.job-type-button::after {
    content: ''; /* Empty content for the box */
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #373737; /* Color for the empty box */
    border-radius: 8px; /* Rounded edges */
    display: flex;
    align-items: center;
    justify-content: center;  
    transition: background-color 0.3s ease;
}

.job-type-button.selected::after {
    background-color: #0070F0; /* Color for the box when selected */
    content: '\2713'; /* Unicode for checkmark */
    color: #FFFFFF; /* White color for the checkmark */
    font-size: 18px;
}

.job-type-button:hover {
  background: #333;
  transform: scale(1.05);
}

.job-type-button.selected {
  background: #0070F0;
}

.job-type-button .icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 17px;
  height: 17px;
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  margin-bottom: 8px;
}

.job-type-button .icon img {
  width: 100%;
  filter: brightness(2);
  filter: saturate(0);
  height: auto;
}

.job-type-button.selected .icon img {
  filter: brightness(1);
  filter: saturate(1);
}

.job-type-button:hover .icon img {
  filter: brightness(70%);
}

.job-type-button .title {
  color: #FFF;
  font-family: Inter, sans-serif;
  font-size: 25.63px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: normal;
  text-align: left;
}

.job-type-button .price-container {
  text-align: right;
  margin-top: auto; /* Pushes the price-container to the bottom */
}

.job-type-button .price {
  display: block;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.job-type-button .approx {
  display: block;
  font-size: 11.21px;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  margin-top: 4px; /* Adjust to control spacing */
}

.job-type-button .description {
  color: rgba(255, 255, 255, 0.5);
  font-family: Inter, sans-serif;
  font-size: 11.21px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  width: 165px;
  height: 31.24px;
}

@media screen and (max-width: 600px) {
  .transfer-form, .details-form {
      max-width: 95%;
      padding: 20px;
  }
}

* {
  font-family: 'Inter', sans-serif;
}

/* Responsive adjustments */
@media screen and (max-width: 700px) {
  .job-type-container {
    flex-direction: column;
    align-items: center;
  }

  .job-type-button {
    width: 100%;
    max-width: 285px; /* Adjusted to match the new width */
    margin-bottom: 20px;
  }
}

.upload-animation {
  width: 100%;
  height: 50px; /* Adjust as needed */
  margin-top: 10px;
}

.file-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 200px; /* Original max height */
  overflow-y: auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0); /* Fully transparent */
  border-radius: 10px;
  margin-bottom: 10px; /* Adjust spacing as needed */
}

.file-list-container-step2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px; /* Increased max height for step 2 */
  overflow-y: auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0); /* Fully transparent */
  border-radius: 10px;
  margin-bottom: 10px; /* Adjust spacing as needed */
}

.file-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-bottom: 8px;
  flex-shrink: 0;
}

.file-thumbnail {
  width: 150px; /* Set a fixed width */
  height: 100px; /* Set a fixed height */
  margin-right: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-thumbnail img,
.file-thumbnail video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

.file-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  color: #ECEDEE;
  margin-bottom: 4px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-name-step1 {
  color: #000 !important; /* Ensure the text color is black */
}

.progress-container {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.progress-bar {
  flex-grow: 1;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #0070F0;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 12px;
  color: #ECEDEE;
  margin-left: 8px;
}

.add-more-files {
  color: #0070F0;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.add-more-files:hover {
  text-decoration: underline;
}

.job-type-button .title {
  color: #FFF;
  font-family: Inter, sans-serif;
  font-size: 25.63px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: normal;
  text-align: left;
}

.job-type-button .price-container {
  text-align: right;
  margin-top: auto; /* Pushes the price-container to the bottom */
}

.job-type-button .price {
  display: block;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.job-type-button .approx {
  display: block;
  font-size: 11.21px;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  margin-top: 4px; /* Adjust to control spacing */
}

.job-type-button .description {
  color: rgba(255, 255, 255, 0.5);
  font-family: Inter, sans-serif;
  font-size: 11.21px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  width: 165px;
  height: 31.24px;
}

@media screen and (max-width: 600px) {
  .transfer-form, .details-form {
      max-width: 95%;
      padding: 20px;
  }
}

* {
  font-family: 'Inter', sans-serif;
}

/* Responsive adjustments */
@media screen and (max-width: 700px) {
  .job-type-container {
    flex-direction: column;
    align-items: center;
  }

  .job-type-button {
    width: 100%;
    max-width: 285px; /* Adjusted to match the new width */
    margin-bottom: 20px;
  }
}

.upload-animation {
  width: 100%;
  height: 50px; /* Adjust as needed */
  margin-top: 10px;
}

.file-list-container {
  display: flex;
  width: 100%; /* Changed from fixed width to 100% for responsiveness */
  height: auto; /* Changed from fixed height to auto */
  flex-direction: column;
  align-items: flex-start;
  gap: 10px; /* Added gap for better spacing */
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 15px;
  background: rgba(255, 255, 255, 0); /* Fully transparent */
  border-radius: 10px;
}

.file-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0; /* Reduced padding */
  background: transparent;
  border-radius: 5px;
}

.file-thumbnail {
  width: 150px; /* Set a fixed width */
  height: 100px; /* Set a fixed height */
  margin-right: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-thumbnail img,
.file-thumbnail video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

.file-thumbnail img.placeholder {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

.file-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.file-name {
    height: 21px;
    align-self: stretch;
    
    color: #ECEDEE;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    
    /* Additional properties to ensure proper display */
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    /* Align text to the left */
    text-align: left;
}

.file-name-step1 {
    color: #000 !important; /* Ensure the text color is black */
}

.submit-button {
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
}

.discount-text {
    text-align: center;
    color: #4CAF50;
    margin-top: 10px;
    margin-bottom: -16px;
}

.upload-progress {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EAEAEA;
}

.progress-bar {
    width: 80%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4285F4;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    font-size: 16px;
    color: #333;
}

.upload-progress.complete .progress-bar-fill {
    background-color: #4CAF50;  /* Green color for completion */
}

.upload-progress.complete .progress-text {
    color: #4CAF50;
}

.circular-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress-inner {
    width: 80px;
    height: 80px;
    position: relative;
}

.circular-progress-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #4285F4;
}

.file-upload-container.upload-complete {
    pointer-events: none;  /* Prevents any interaction with the upload area */
}

.lets-rock-button {
    max-height: 54px;
    color: #EAEAEA;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    border-radius: 12px;
    line-height: 20px; /* 86.957% */
}

/* Tooltip container */
.tooltip-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

/* Tooltip text */
.tooltip-text {
    visibility: hidden;
    width: 200px; /* Adjust the width as needed */
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -100px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.file-list-container .file-item .file-info .file-name-step1 {
    color: #000 !important; /* Ensure the text color is black */
}

.notice-text {
    margin-top: 10px;
    color: rgba(105, 135, 252, 0.848);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.terms-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.terms-container input[type="checkbox"] {
    margin-right: 10px;
}

.terms-container a {
    color: #8dc2ff;
    text-decoration: underline;
}

.terms-container a:hover {
    color: #005bb5;
}